// En este documento se encuentran las funciones generales del template 
document.addEventListener("turbolinks:load", () => {
	// if (document.getElementById("header-container-body")){

	// }
	// // alto del body
	// let body_height = document.body.offsetHeight
	// let header_height = document.getElementById("navbar-header").offsetHeight;
	// let container_body = document.getElementById("container-body");
	// let header_container_body_height = document.getElementById("header-container-body") ? document.getElementById("header-container-body").offsetHeight : 0;
	// let container_body_height = body_height-header_height;

	// // Establecer variable en estilo inline
	// document.documentElement.style.setProperty('--height-body', body_height+'px');		

	// container_body.style.height = container_body_height+"px";
	// if(document.getElementById("header-container-body")){
	//     document.getElementsByClassName("table-responsive")[0].style.height = (container_body_height-(header_container_body_height+10))+"px";
	// }

	let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	let touchDevice = ('ontouchstart' in document.documentElement);

	// buscadores generales
	search_form = document.getElementById("all_search");
	if (search_form) {
		document.querySelectorAll('input[form="all_search"]').forEach(input => {
			input.addEventListener('keyup', event => makeAjaxSearch(event));
		});
		search_form.querySelectorAll('input').forEach(input => {
			input.addEventListener('keyup', event => makeAjaxSearch(event));
		})
		search_form.addEventListener('submit', event => makeAjaxSearch(event));

		let makeAjaxSearch = (ev) => {
			ev.preventDefault();
			$.get(search_form.action, $(search_form).serialize(), null, 'script').always(function () { });
			return false;
		}
	}

	// buscadores selects
	let search_selects = document.querySelectorAll('.select_search');
	if (search_selects.length > 0) {
		search_selects.forEach(el => {
			el.addEventListener('change', ev => {
				$.get(el.form.action, $(el.form).serialize(), null, 'script').always(function () { });
			});
		});
	}

	// // Mostrar u Ocultar el menu lateral
	// let menu_lateral = document.getElementById("sidebarMenu");
	// if(menu_lateral && menu_lateral.getElementsByClassName("nav-link").length < 2){
	// 	menu_lateral.remove();
	// 	var content = document.getElementsByClassName("content");
	// 	content[0].style.width = "100%";
	// }

	// Funcion para crear una validacion en el fron-end para los input para telefono, agregar la calse phone a estos imputs
	$(document).on('input', '.phone', function (event) {
		// Agrega la clase invalid si comenzó a escribir algo en el input
		if (event.target.value.length > 0) {
			event.target.classList.remove("is-valid")
			event.target.classList.add("is-invalid")
			// Si los digitos ingresados son igual a 10 le agrega la clase valid
			if (event.target.value.length == 10) {
				event.target.classList.remove("is-invalid");
				event.target.classList.add("is-valid");
			}
		} else {
			event.target.classList = "form-control phone";
		}
	});

	$('.filters-form').on('change', (e) => {
		applyFilters()
	})

	function applyFilters() {
		let formData = {};
		let url = $("#filters-form").attr('action');
		//   console.log(url);
		$('.filters-form').each(function () {
			formData[$(this).attr('name')] = $(this).val();
		});

		$.ajax({
			url: url,
			method: 'GET',
			data: formData,
			dataType: 'html',
			success: function (response) {
				$('#changes').html(response);
			}
		});
	}

	$('#pdf_file_input').on('change', function () {
		var fileName = this.files[0].name;
		var label = $('#file-name');
		label.text(fileName); // Usa .text() para establecer el texto
	});

	function toggleNavbarAside() {
		if ($("#body").hasClass('navbar-vertical-aside-closed-mode') && $("#body").hasClass('navbar-vertical-aside-mini-mode')) {
			$("#body").removeClass('navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode');
		} else if ($("#body").hasClass('navbar-vertical-aside-mini-mode')) {
			$("#body").removeClass('navbar-vertical-aside-mini-mode');
		} else if ($(window).width() <= 1300 && $(window).width() > 1200) {
			// 1252
			$("#body").addClass('navbar-vertical-aside-mini-mode');
		} else if ( $(window).width() > 1300) {
			$("#body").addClass('navbar-vertical-aside-mini-mode');
		} else {
			$("#body").addClass('navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode');
		}
	}

	$('.navbar-aside-toggler').on('click', function () {
		toggleNavbarAside();
		toggleMenuOnMiniMode();
	});

	$(window).on('resize', function () {
		enableNavBar();
		toggleMenuOnMiniMode();
	});

	$(document).ready(function () {
		enableNavBar();
		toggleMenuOnMiniMode();
	});

	function enableNavBar() {
		let windowWidth = $(window).width();
		// console.log(windowWidth);

		if (windowWidth <= 1300 && windowWidth > 1200) {
			if (!$("#body").hasClass('navbar-vertical-aside-mini-mode')) {
				$("#body").addClass('navbar-vertical-aside-mini-mode');
			}
			if ($("#body").hasClass('navbar-vertical-aside-closed-mode')) {
				$("#body").removeClass('navbar-vertical-aside-closed-mode');
			}
		}
		else if (windowWidth <= 1200) {
			if (!$("#body").hasClass('navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode')) {
				$("#body").addClass('navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode');
			}
		}
		else {
			if ($("#body").hasClass('navbar-vertical-aside-closed-mode') && $("#body").hasClass('navbar-vertical-aside-mini-mode')) {
				$("#body").removeClass('navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode');
			} else if ($("#body").hasClass('navbar-vertical-aside-mini-mode')) {
				$("#body").removeClass('navbar-vertical-aside-mini-mode');
			}
		}
		$(".collapse-menu").each(function () {
			var $toggle = $(this);
			var $menu = $($toggle.attr("data-target"));
			if (!$toggle.is(":focus")) {
				$menu.css({
					display: "",
				}).removeClass('show');
				setTimeout(function () {
					$menu.css({
						top: ""
					});
				}, 300);
			}
		})
	}

	function toggleMenuOnMiniMode() {
		$(".collapse-menu").each(function () {
			var $toggle = $(this);
			var $menu = $($toggle.attr("data-target"));

			$toggle.off("click blur");

			if ($("#body").hasClass("navbar-vertical-aside-mini-mode")) {
				$toggle.on("click", function () {
					var offset = $toggle.offset();
					if ($menu.is(":visible")) {
						setTimeout(function () {
							$menu.removeClass('show');
							$menu.css({
								display: "",
							});
							setTimeout(function () {
								$menu.css({
									top: ""
								});
							}, 300);
						}, 200);
					} else {
						var offset = $toggle.offset();
						var menuHeight = $menu.outerHeight();
						var windowHeight = $(window).height();

						// Calcula si el menú cabe en la pantalla desde la posición actual
						var newTop = offset.top + $toggle.outerHeight();
						if (newTop + menuHeight > windowHeight) {
							// Si el menú se sale de la pantalla, ajusta su posición hacia arriba
							newTop = windowHeight - menuHeight - 20; // Añadimos un margen de 20px para evitar solapamiento con el borde
							if (newTop < 0) newTop = 0; // Asegúrate de que no sea negativo
						}

						// Aplica las nuevas posiciones ajustadas
						$menu.css({
							top: newTop,
							opacity: 1,
							height: 'auto',
							display: 'block'
						})
					}

				});
				$toggle.on("blur", function () {
					setTimeout(function () {
						if (!$toggle.is(":focus")) {
							$menu.css({
								display: "",
							}).removeClass('show');
							setTimeout(function () {
								$menu.css({
									top: ""
								});
							}, 300);
						}
					}, 200);
				});
			} else {
				$toggle.off("click blur");
			}
		});
	}
	setTimeout(() => {
		const scrollableContainer = document.querySelector(".nav-areas"); 
		const activeItem = scrollableContainer?.querySelector(".active");
	
		if (scrollableContainer && activeItem) {
		  scrollableContainer.scrollLeft = activeItem.offsetLeft - scrollableContainer.offsetLeft;
		}
	}, 100);
});