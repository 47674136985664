document.addEventListener("turbolinks:load", function() {
	console.log('hola desde data_products.js');
	//buscar el input de tipo de producto
	let type_data_product = document.getElementById("data-product-type-product");
	//console.log(type_data_product)

	//si existe el campo tipo de producto (está en el form de productos)
	if (type_data_product) {
		//campo de clave en el formulari de productos
		let key_code_field = document.querySelector("#key_code_field");
		//si se esta editando se bloquea el campo de clave
		if (key_code_field.dataset.id) key_code_field.setAttribute("readonly", "readonly");

		//funcion para ver si existe la clave de producto escrita el el campo
		let codeKeyProductExists = () => { //se llama en el callback change del campo clave y desde el armar grupos
			//$(".loader").show();
			let route_path = type_data_product.dataset.routeDataProducts;
			let key_code = document.querySelector("#key_code_field").value;
			let id_key_code = document.querySelector("#key_code_field").dataset.id;
			//console.log(route_path)
			//var response = false;
			fetch("/"+route_path+"/check_key_code.json?key_code="+key_code+"&id="+(id_key_code||0))
				.then(resp => resp.json())
				.then(resp => {
					//console.log(resp);
					if(resp){ //si exsite mandar mensaje de que ya existe
						//response = true; 
						alert("La clave ya ha sido utilizada en otro producto");
						//y poner el data attribute en true
						key_code_field.dataset.existing=  'true';
					}else { //si no existe poner el data attribute en ""
						console.log("Clave disponible");
						key_code_field.dataset.existing=  '';
					}
				}).catch(err => {
					console.log(err);
				});
		}
		//funcion para desbloquear el campo de clave en casode que este bloqueado por armar grupos
		let unlockKeyCode = () => { //se llama en el callback del onclick del campo clave
			//console.log('desbloquear campo')
			//console.log(document.querySelector("#key_code_field").hasAttribute("readonly"))
			if (document.querySelector("#key_code_field").hasAttribute("readonly")) { //si el campo esta bloqueado
				//console.log('estaba bloqueado el campo clave')
				if (!document.querySelector("[data-type='nested-fields-product-group']") && !document.querySelector("[data-type='nested-fields-product']")) {
					//si no hay nested de variaciones quitar el aatributo readonly
					key_code_field.removeAttribute("readonly");
				}else{
					//si hay variaciones dar el mensaje de que no se puede editar
					alert("Para poder desloquear la clave tienes que eliminar las combinaciones generadas");
				}
			}
		}


		//primera funcion para el onchange de el select de tipo de producto en el formulario
		let changeProductLineTypes = () => { //se llama al inicializar y en el onchange del campo tipo en el form de productos
			let string_type = type_data_product.value; //obtener el tipo de producto seleccionado
			// console.log(string_type);
			showHideCreateGroupContainer(string_type);
			if (!type_data_product.dataset.editing) { //solo si no esta editando el producto
				//cambiar el select de lineas
				changeOptionsProductLines(string_type);
				if (string_type == "product_group") {
				  //cuando es grupo
				  //limpiar el div de los productos
				  document.querySelector("#products-list").innerHTML = "";
				}else{
				  //cuando es producto
				  //agregar un solo producto
				  addSingleProduct();
				}
			}
		}

		let showHideCreateGroupContainer = (string_type) => {
			// console.log(string_type)
			if (string_type == "product_group") {
				//mostrar el boton para armar grupos
				document.querySelector("[data-type='create-group-container']").style.display = 'block' ;
				$("#group-products-list").removeClass('d-none')
			}else{
				//ocultar el boton para generar grupos
				document.querySelector("[data-type='create-group-container']").style.display = 'none' ;
				$("#group-products-list").addClass('d-none')
				$('#combinations-lists').empty();
				$("#add-group").addClass('d-none')
			}
		}
		//segunda funcion para hacer la peticion de las lineas y hacer el cmabio al select de line_id
		let changeOptionsProductLines = (type_product) => {
			//$(".loader").show();
			let route_path = type_data_product.dataset.routeProductLines;
			//console.log(type_data_product.dataset)
			let product_lines_select = document.getElementById("product_line_id_field");
			product_lines_select.setAttribute("disabled", "disabled");
			//console.log(route_path);
			fetch("/"+route_path+"/all_by_type.json?type="+type_product)
				.then(res => res.json())
				.then( response => {
					//console.log(response);
					let opt = "<option></option>";
					response.forEach(function (el) {
						opt += "<option value='"+el.id+"'>"+el.key_code+"</option>";
					});
					//console.log(document.getElementById("product_line_id_field"))
					product_lines_select.innerHTML = opt;
					product_lines_select.removeAttribute("disabled");
				}).catch( err => {
					console.log(err);
				});
		}


		//funcion para poner un solo producto
		let addSingleProduct = () => {
			let link = document.querySelector("#add-element-product");
			//console.log(link)
			//console.log(link.dataset.linkToAddField)
			let min_stock = (document.querySelector("#min_stock_field").value || 0);
			let max_stock = (document.querySelector("#max_stock_field").value || 0);
			time         = new Date().getTime() 
			regexp       = new RegExp(link.dataset.linkToAddFieldId, 'g')
			fields_html  = link.dataset.linkToAddField.replace(regexp, time)
				.replace("clave_compuesta_valor", "")
				.replace("uno_clave_valor", "")
				.replace("dos_clave_valor", "")
				.replace(/stock_min_valor/g, min_stock)
				.replace(/stock_max_valor/g, max_stock);
			//agregar campo a la tabla de productos
			document.querySelector(link.dataset.associationInsertionNode).innerHTML = fields_html;
			//agregar callbacks a los botones de agregar almacen
			addCallbackAddWarehouseButtons();
			//ocultar los inputs de subclave por cada producto
			let group_subcodekey_fields = document.querySelectorAll("[data-type='group-subcodekey']");
			group_subcodekey_fields.forEach(fld => {fld.style.display = 'none'});
		}

		//funcion para agregar los callbacks a los botones de agregar almacen por cada variacion
		let addCallbackAddWarehouseButtons = () =>{
			//identificar todos los botones de agrerar almacen en el _product_fields
			let addWarehouses = document.querySelectorAll('[data-link-to-add-warehouse]');
			if (addWarehouses) { //si hay botones ponerle los callbacks
				addWarehouses.forEach(link_add => {
					//console.log(link_add.dataset.hasCallbackAddWarehouse)
					if (!link_add.dataset.hasCallbackAddWarehouse) { //si el link no tiene aun el callback agregarlo
						link_add.addEventListener('click', e => {
							//console.log('ejecucion listener');
							e.preventDefault();
							let link = e.target; //obtener el boton
							let stock_min = (document.querySelector("#min_stock_field").value || 0); //stock min general
							let stock_max = (document.querySelector("#max_stock_field").value || 0); //stock max general
							time = new Date().getTime();
							//console.log(link)
							regexp       = new RegExp(link.dataset.linkToAddWarehouseId, 'g');
							fields_html  = link.dataset.linkToAddWarehouse
								.replace(regexp, time)
								.replace(/77172954/g, stock_min)
								.replace(/7425439/g, stock_max); //sobrescribir datos que se asignan en el data_products_helper
							//console.log(fields_html)
							let padre = link.closest("[data-type='stocks-table']"); //identificar la tabla donde se van a insertar
							let alm_id_btn = link.dataset.almIdBtn; //identificar el id del almacen
							//console.log(alm_id_btn)
							let existe = padre.querySelector('[data-warehouse_id="'+alm_id_btn+'"]'); //ver si existen ya los campos del almacen
							//console.log(existe)
							//solo se agrega si no existe
							//console.log($(padre).find('tbody'))
							if (!existe){ //si no existe poner los campos para el almacen
								$(padre.querySelector('tbody')).prepend(fields_html);
							}else { //si no, dar mensaje de que ya existe el producto ya existe para ese almacen
								alert("El producto ya existe en ese almacén");
							}
						});
						//poner data attribute para saber que ya tiene el callback
						link_add.dataset.hasCallbackAddWarehouse = "true";
					}
				});
			}
		}

		//bloquear poder escribir caracteres especiales en los campos de vlave y subclaves
		let lockCodeKeySpecialCharacters = (elements_ids) => {
			// console.log(elements_ids)
			elements_ids.forEach((el) => {
				document.getElementById(el).addEventListener('keypress', (event) => {
				    let regex = new RegExp("^[a-zA-Z0-9]+$");
				    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
				    if (!regex.test(key)) {
				       event.preventDefault();
				       return false;
				    }
				});
			});
		}

		let subgroups_one = [];
		let subgroups_two = [];
		let separator_one = '';
		let separator_two = '';
		
		//-----------INICIALIZAR ELEMENTOS Y PONER CALLBACKS FORMULARIO DE PRODUCTOS------------

		//al dar clic en armar grupos cuando es un grupo de productos
		document.querySelector('[data-link-to-add-field]').addEventListener('click', e => {
		  //console.log('al dar clic en armar grupos')
		  e.preventDefault()
		  let link = e.target;
		  let line = (document.querySelector("#product_line_id_field").value);
		  let key_code = document.querySelector("#key_code_field").value;
		  //console.log(linea)
		  codeKeyProductExists(); //verificar otra vez clave
		  //console.log(document.querySelector("#key_code_field").dataset);
		  //console.log('NO HAY CLAVE')
		  if (key_code == "") {
		  	alert("Ingresa una clave válida");
		  }else if (line == "") {
		  	alert("Selecciona una linea válida");
		  }else if (document.querySelector("#key_code_field").dataset.existing == 'true') {
		  	alert("La clave ya ha sido utilizada, porfavor ingresa una diferente");
		  }else{ //si tiene seleccionada una linea, si tiene una clave y la clave no ha sido usada
			//$(".loader").show(); ////////////////////////////////////HASTA AQUI
			$("#confirmModal").modal('show');
			const confirmYesButton = document.getElementById('btn-confirm-yes');
			const confirmNoButton = document.getElementById('btn-confirm-no');
			let userConfirmed = false;

			$(confirmYesButton).off('click');
			$(confirmNoButton).off('click');

			$(confirmYesButton).one('click', function() {
				// console.log('OK');
				userConfirmed = true;
				$("#warehouseModal").modal('show');
				add_group(userConfirmed, link)
			});
	
			$(confirmNoButton).one('click', function() {
				// console.log('NO');
				userConfirmed = false;
				$("#warehouseModal").modal('show');
				$("#add-group").removeClass('d-none')
				add_group(userConfirmed, link)
			});
		  }
		  
		});

		function add_group(option, link) {
			let line = (document.querySelector("#product_line_id_field").value);
			let route_path = type_data_product.dataset.routeProductLines;
			let key_code = document.querySelector("#key_code_field").value;
			let min_stock = (document.querySelector("#min_stock_field").value || 0);
			let max_stock = (document.querySelector("#max_stock_field").value || 0);
			let all_fields = "";
			if (option) {
				fetch("/"+route_path+"/"+line+".json")
					.then(resp => resp.json())
					.then(resp => {
						// console.log(resp);
						let combinations = [];
						separator_one = resp.separator_one || "-";
						separator_two = resp.separator_two || "-";
						resp.subgroups_one.forEach(({ value: subgroupOneValue }) => {
							resp.subgroups_two.forEach(({ value: subgroupTwoValue }) => {
								combinations.push({
									subgroup_one: subgroupOneValue,
									subgroup_two: subgroupTwoValue,
									composed_key_code: `${key_code}${separator_one}${subgroupOneValue}${separator_two}${subgroupTwoValue}`
								});
							});
						});
						// Cambiar valor de los th
						document.getElementById("tag-sub-one").innerHTML = resp.tag_sub_one;
						document.getElementById("tag-sub-two").innerHTML = resp.tag_sub_two;
	
						// Obtener subgrupos
						subgroups_one = resp.subgroups_one;
						// separator_one = resp.separator_one;
	
						subgroups_two = resp.subgroups_two;
						// separator_two = resp.separator_two;
	
						// console.log(combinations)
	
	
						const okButton = document.getElementById('btn-save');
						$(okButton).off('click');
	
						$(okButton).one('click', function() {
							const checkboxes = document.querySelectorAll('.warehouse-checkbox:checked');
							let selectedWarehouses = Array.from(checkboxes).map(checkbox => ({
								id: checkbox.value,
								description: checkbox.getAttribute('data-description')
							}));
							// console.log(selectedWarehouses);
							// Si el usuario desea que se realicen las combinaciones automáticamente
							// if (userConfirmed) {
								selectedWarehouses.forEach((warehouse) => {
								  combinations.forEach(el => {
									if (!document.getElementById(`${el.composed_key_code}-${warehouse.id}`)) { // Verificar si el elemento ya existe
										let time = Math.round(Math.random() * 100000000000); 
										let regexp = new RegExp(link.dataset.linkToAddFieldId, 'g');
										let fields_html = link.dataset.linkToAddField.replace(regexp, time)
										  .replace("clave_compuesta_valor", el.composed_key_code)
										  .replace("uno_clave_valor", el.subgroup_one)
										  .replace("dos_clave_valor", el.subgroup_two)
										  .replace("producto_unico", el.composed_key_code)
										  .replace(/id_warehouse/g, warehouse.id)
										  .replace(/stock_min_valor/g, min_stock)
										  .replace(/stock_max_valor/g, max_stock)
										  .replace("nested-fields-product", "nested-fields-product-group")
										  .replace('<tr class="nested-fields product-fields" >', `<tr class="nested-fields product-fields" id="${el.composed_key_code}-${warehouse.id}">`)
										  .replace('<td class="warehouse_description"></td>', `<td class="warehouse_description">${warehouse.description}</td>`);
					  
										all_fields += fields_html;
									  }
								  });
								});
								// Insertar nested fields
								$(link.dataset.associationInsertionNode).prepend(all_fields);
	
								// Limpiar checks
								checkboxes.forEach(checkbox => {
									checkbox.checked = false;
								});
							// }			
						});
								
	
						addCallbackAddWarehouseButtons();
						// //mostrar los imputs de subclave por cada producto
						// let group_subcodekey_fields = document.querySelectorAll("[data-type='group-subcodekey']");
						// group_subcodekey_fields.forEach(fld => {fld.style.display = 'block'});
						if (resp.subgroups_one.length == 0 || resp.subgroups_two.length == 0) {
							alert("La linea debe contener un elemento de cada "+resp.tag_sub_one+"/"+resp.tag_sub_two)
						}else{
							//bloquea clave para que no se pueda editar hasta que se eliminen todos los nested generados
							document.querySelector("#key_code_field").setAttribute("readonly", "readonly");
							//$("#unlock-clave").removeClass('d-none');
							// console.log(combinations_number+" nuevas combinaciones agregadas");	
						}
					}).catch(err => {
						console.log(err);
						console.log("Error al obtener linea");
					});
			} else {
				fetch("/"+route_path+"/"+line+".json")
					.then(resp => resp.json())
					.then(resp => {
						// Obtener subgrupos
						subgroups_one = resp.subgroups_one;
						subgroups_two = resp.subgroups_two;
					}).catch(err => {
						console.log(err);
						console.log("Error al obtener linea");
					});
				const btn_add_group = document.getElementById("add-group");

				// Agregar nested individualmente 
				$(btn_add_group).on('click', function(e) {
					e.preventDefault();
				    if (link.dataset.linkToAddField) {
				        let time = Math.round(Math.random() * 100000000000);
        				let regexp = new RegExp(link.dataset.linkToAddFieldId, 'g');
						let key_code = document.querySelector("#key_code_field").value;
						let composed_key_code = (key_code + (separator_one || "-")+ subgroups_one[0].value + (separator_two || "-") + subgroups_two[0].value);
						let stock_min = (document.querySelector("#min_stock_field").value || 0); //stock min general
						let stock_max = (document.querySelector("#max_stock_field").value || 0); //stock max general
        				// Inserta el HTML
        				let newFields = $(link.dataset.linkToAddField.replace(regexp, time).replace("clave_compuesta_valor", composed_key_code).replace(/stock_min_valor/g, stock_min).replace(/stock_max_valor/g, stock_max))

        				$(link.dataset.associationInsertionNode).prepend(newFields);
					
        				// Dispara el evento cocoon:after-insert
        				$(link.dataset.associationInsertionNode).trigger('cocoon:after-insert', [newFields, null]);
				    } else {
				        console.error('No se encontró.');
				    }
				});
			

				$('#combinations-lists').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
					// console.log(insertedItem);
					// console.log(subgroups_one);
					let key_code = document.querySelector("#key_code_field").value;
					const checkboxes = document.querySelectorAll('.warehouse-checkbox:checked');
					let selectedWarehouses = Array.from(checkboxes).map(checkbox => ({
						id: checkbox.value,
						description: checkbox.getAttribute('data-description')
					}));

					//Generar select de almacenes
					let warehouseInput = insertedItem.find('input[name*="[warehouse_id]"]');
					if (warehouseInput.length > 0) {
						let selectWarehouse = $('<select class="form-control warehouse_id"></select>');
    				    selectWarehouse.attr('name', warehouseInput.attr('name'));
    				    selectWarehouse.attr('id', warehouseInput.attr('id'));
    				    selectedWarehouses.forEach(option => {
    				        selectWarehouse.append($('<option></option>').attr('value', option.id).text(option.description));
    				    });
						warehouseInput.replaceWith(selectWarehouse);
					
        				let tdWarehouseDescription = insertedItem.find('td.warehouse_description');
        				if (tdWarehouseDescription.length > 0) {
        				    tdWarehouseDescription.append(selectWarehouse);
        				}
					}
				
					// Generar select de subgrupo uno
					let inputSubOne = insertedItem.find('input[name*="[code_key_sub_one]"]');
    				if (inputSubOne.length > 0) {
    				    let selectSubOne = $('<select class="form-control code-key-sub-one"></select>');
    				    selectSubOne.attr('name', inputSubOne.attr('name'));
    				    selectSubOne.attr('id', inputSubOne.attr('id'));
    				    subgroups_one.forEach(option => {
    				        selectSubOne.append($('<option></option>').attr('value', option.value).text(option.value));
    				    });
    				    inputSubOne.replaceWith(selectSubOne);
    				}
				
					// Generar select de subgrupo dos
					let inputSubTwo = insertedItem.find('input[name*="[code_key_sub_two]"]');
    				if (inputSubTwo.length > 0) {
    				    let selectSubTwo = $('<select class="form-control code-key-sub-two"></select>');
    				    selectSubTwo.attr('name', inputSubTwo.attr('name'));
    				    selectSubTwo.attr('id', inputSubTwo.attr('id'));
    				    subgroups_two.forEach(option => {
    				        selectSubTwo.append($('<option></option>').attr('value', option.value).text(option.value));
    				    });
    				    inputSubTwo.replaceWith(selectSubTwo);
    				}
				
					$('.code-key-sub-one').on('change', (e) => {
						let subOneValue = $(e.target).val();
						let subTwoValue = insertedItem.find('.code-key-sub-two').val();
						let composed_key_code_field = insertedItem.find('input[name*="[composed_key_code]"]');
					
						let new_key_code = (key_code + (separator_one || "-")+ subOneValue + (separator_two || "-") + subTwoValue);
					
						$(composed_key_code_field).val(new_key_code)
						// console.log(composed_key_code_field);
					});
				
					$('.code-key-sub-two').on('change', (e) => {
						let subOneValue = insertedItem.find('.code-key-sub-one').val();
						let subTwoValue = $(e.target).val();
						let composed_key_code_field = insertedItem.find('input[name*="[composed_key_code]"]');
					
						let new_key_code = (key_code + (separator_one || "-")+ subOneValue + (separator_two || "-") + subTwoValue);
					
						$(composed_key_code_field).val(new_key_code)
						// console.log(composed_key_code_field);
					});
				})
			}
		}

		//callback en el onchange de el campo de clave para revisar si ya existe un producto con esa clave
		if (key_code_field) { //si existe el campo de clave
			key_code_field.addEventListener("change", e => { //al cambiar valor de la clave de productos
				//console.log(e.target.value)
				//console.log("al cambiar la calve ve si ya existe")
				codeKeyProductExists(); //revisa si existe la clave
			});
			key_code_field.addEventListener("click", e => { //callback para tratar de desbloquear el campo si esta bloqueado al dar clic
				//console.log('al hacer click revisa si esta bloqueado y trata de desbloquear')
				unlockKeyCode();
			})
		}

		//inicializar el tipo de producto de acuerdo a lo que tiene el select del formulario en el campo tipo
		changeProductLineTypes();
		//agregar el listener a el select de tipo de producto
		type_data_product.addEventListener("change", event => { //al cambiar el tipo de producto desde el select
			changeProductLineTypes();
		});

		document.getElementById("product_line_id_field").addEventListener("change", () => {
			// console.log(type_data_product.value)
			if (type_data_product.value == "product_group") {
				//quitar los generadosde otra linea
				document.getElementById("products-list").innerHTML = "";
			}
		});

		//inicializar los callbacks para los botones de agregar almacen
		addCallbackAddWarehouseButtons();

		lockCodeKeySpecialCharacters(['key_code_field']);

	}


	// para el index
	let btn_open_modal_import = document.getElementById('import-products-btn');
	if (btn_open_modal_import) {
		// console.log(btn_open_modal_import)
		btn_open_modal_import.addEventListener('click', ev => {
			$('#modal-import-products').modal('show');
		});
	}

});