import LocalTime from "local-time";

LocalTime.config.i18n["es"] = {
  date: {
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "jueves", "Viernes", "Sábado"],
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    abbrDayNames: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    abbrMonthNames: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
  },
  time: {
    am: "AM",
    pm: "PM"
  },
  datetime: {
    distanceInWords: {
      halfAMinute: "medio minuto",
      lessThanAMinute: "menos de un minuto",
      lessThanXMinutes: {
        one: "menos de un minuto",
        other: "menos de %{count} minutos"
      },
      xMinutes: {
        one: "1 minuto",
        other: "%{count} minutos"
      },
      aboutXHours: {
        one: "alrededor de 1 hora",
        other: "alrededor de %{count} horas"
      },
      xDays: {
        one: "1 día",
        other: "%{count} días"
      },
      aboutXMonths: {
        one: "alrededor de 1 mes",
        other: "alrededor de %{count} meses"
      },
      xYears: {
        one: "1 año",
        other: "%{count} años"
      }
    }
  }
};

LocalTime.config.locale = "es";
LocalTime.config.useFormat24 = false