document.addEventListener("turbolinks:load", function () {
	const formModels = document.getElementById("models-form")
	if (formModels) {
		// initialize existing selects
		const selectElements = $(".select3")
		if (selectElements.length > 0) {
			selectElements.each(function () {
				let route = $(this).data("route");
				if ($(this).data('type') == 'nested-product-id' || $(this).data('type') == 'product-id') {
					initializeSelect2Models($(this), route, true);
				} else {
					initializeSelect2Models($(this), route, false);
				}
			});
		}

		function initializeSelect2Models(selector, route, habilitation) {
			$(selector).select2({
				language: {
					"noResults": function () {
						return "Sin resultados";
					}
				},
				ajax: {
					url: (route + ".json"),
					data: function (params) {
						var query = {
							description: params.term,
						}

						return query;
					},
					processResults: function (data) {
						// console.log(data);
						// Transforms the top-level key of the response object from 'items' to 'results'
						var results = $.map(data, function (obj) {
							if (habilitation) {
								obj.text = obj.composed_description || obj.description;
								obj.id = obj.id;
								obj.data_unit = obj.output_unit;
								obj.data_product_id = obj.product_id;
							} else {
								obj.text = obj.description;
								obj.id = obj.product_id;
							}
							if (parseInt(obj.stock) === 0) {
								obj.disabled = true; // Desactiva la opción
							}
							return obj;
						});

						return {
							results: results,
						}
					}
				},
				placeholder: 'Selecciona una opción',
				templateResult: formatRepo,
				templateSelection: formatRepoSelection
			})
		}


		// initialize when inserting new nesteds
		$('#thread_models').on('cocoon:after-insert', function (e, insertedItem) {
			$(insertedItem).find('select[name*="[list_id]"]').select2();
			var selectInElement = $(insertedItem).find('select[name*="[product_id]"]');
			const selector = "#" + selectInElement.attr('id');

			initializeSelect2Models(selector, selectInElement.data('route'), true);

			$(selector).on('select2:select', function (e) {
				var selectedElement = e.params.data;
				let parent = $(selector).closest(".nested-fields");
				// console.log(selectedElement);
				
				let data_product_field = parent.find("[data-type='data-product-id']");
				data_product_field.val(selectedElement.product_id);
			});
		})

		// initialize when inserting new nesteds
		$('#product_models').on('cocoon:after-insert', function (e, insertedItem) {
			var selectInElement = $(insertedItem).find('select:first');
			const selector = "#" + selectInElement.attr('id');

			initializeSelect2Models(selector, selectInElement.data('route'), true);

			$(selector).on('select2:select', function (e) {
				var selectedElement = e.params.data;
				let parent = $(selector).closest(".nested-fields");
				let unit_nested_field = parent.find("[data-type='unit-nested-field']");
				let data_product_field = parent.find("[data-type='data-product-id']");
				unit_nested_field.val(selectedElement.data_unit);
				data_product_field.val(selectedElement.product_id);
			});
		})
	}

	// Función para formatear los resultados
	function formatRepo(repo) {
		if (repo.loading) {
			return repo.text;
		}

		var $container = $(
			"<div class='select2-result-repository clearfix d-flex'>" +
			"<div class='select2-result-repository__title'></div>" +
			"</div>"
		);

		// Añadir información de stock si es 0
		if (parseInt(repo.stock) === 0) {
			$container.append("<span class='text-danger'> (Sin stock)</span>");
		}

		$container.find(".select2-result-repository__title").text(repo.text);
		$container.attr('data-unit', repo.data_unit); // Agregamos el atributo data-type
		$container.attr('data_product_id', repo.data_product_id); // Agregamos el atributo data_product_id

		return $container;
	}

	// Función para formatear la selección
	function formatRepoSelection(repo) {
		return repo.text;
	}

	function updateThreadValues(id, parent) {
		// let parentElement = $(selector).closest(".nested-fields");
		fetch("/search_thread_values?id=" + id + ".json")
			.then(resp => resp.json())
			.then(resp => {
				// console.log(resp);
				if(resp.error) {
					parent.find("td[data-type='thread-color']").text(null);
					parent.find("td[data-type='thread-gauge']").text(null);
				} else {
					parent.find("td[data-type='thread-color']").text(resp.color);
					parent.find("td[data-type='thread-gauge']").text(resp.gauge);
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
	let habilitation_fields = $(".habilitation");
	habilitation_fields.each((index, element) => {
		$(element).on('select2:select', function(e) {
			let parentElement = $(this).closest('tr');
			var selectedElement = e.params.data;
			parentElement.find("input[data-type='data-product-id'").val(selectedElement.product_id)
		});
	});

	let lastModelSelect = $('#specifications_models select:last');

	$('#specifications_models').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
		let type_nested_field = insertedItem[0].querySelector("input[data-type='type_field']");
		type_nested_field.value = 'front';

		let previousSelect = $(insertedItem).prev().find('select:first');
		let selectedLetter = previousSelect.val() || (lastModelSelect.length > 0 ? lastModelSelect.val() : '');

		let nextLetter = selectedLetter ? String.fromCharCode(selectedLetter.charCodeAt(0) + 1) : 'A';

		let newSelect = $(insertedItem).find('select:first');
		newSelect.val(nextLetter);

		const selector = "#" + newSelect.attr('id');
		$(selector).select2();
	})

	let lastModelTSelect = $('#specifications_models_t select:last');

	$('#specifications_models_t').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
		let typeNestedField = insertedItem[0].querySelector("input[data-type='type_field']");
		typeNestedField.value = 'back';

		let previousSelect = $(insertedItem).prev().find('select:first');
		let lastSelect = $('#specifications_models select:last');

		let selectedLetter = previousSelect.val() || (lastModelTSelect.length > 0 ? lastModelTSelect.val() : lastSelect.length > 0 ? lastSelect.val() : '');

		let nextLetter = selectedLetter ? String.fromCharCode(selectedLetter.charCodeAt(0) + 1) : 'A';

		let newSelect = $(insertedItem).find('select:first');
		newSelect.val(nextLetter);

		const selector = "#" + newSelect.attr('id');
		$(selector).select2();

	});

	$("#btn-select-area").on('click', () => {
		$("#areasModal").modal('show');
	})

	$('#add_area').on('click', function(e) {
		const areasList = document.getElementById("areas-list");
		const btnContainer = document.getElementById("btn-container");
		areasList.clientWidth >= 615 ? btnContainer.classList.add('mb-3') : null;
  
		const areasSelected = document.getElementById("model_areas");
		var areas = areasSelected.value !== "" ? JSON.parse(areasSelected.value) : [];
		var selected = $('#areas_fake').val();
		var option = $('#areas_fake').find("option[value='" + selected + "']");
		var text = option.text();
		var color = option.data('color');
  
		if (selected && !areas.includes(selected)) {
		  areas.push(selected);
		  const child = document.createElement('div');
		  child.classList.add("mr-2");
		  child.innerHTML = `<span data-id="${selected}" class="text-white circle  ${color} delete-text area-circle">${text}</span>`;
		  areasList.appendChild(child);
		}
		areasList.clientWidth >= 615 ? btnContainer.classList.add('mb-3') : null;
	
		areasSelected.value = JSON.stringify(areas);
		$("#areasModal").modal('hide');
		$('#areas_fake').val('');
	});
  
	$(document).off('click', '.circle').on('click', '.circle', function() {
		var dataId = $(this).data('id');
		removeArea(dataId);
	});
  
	function removeArea(area) {
	  const areasList = document.getElementById("areas-list");
	  const btnContainer = document.getElementById("btn-container");
	  const areasSelected = document.getElementById("model_areas");
  
	  var areas = areasSelected.value !== "" ? JSON.parse(areasSelected.value) : [];
	  var index = areas.indexOf(area);
	  
	  if (index !== -1) {
		areas.splice(index, 1);
	  }
	  areasSelected.value = JSON.stringify(areas);
	  areasList.querySelector("[data-id='" + area + "']").remove();
	  areasList.clientWidth < 615 ? btnContainer.classList.remove('mb-3') : null;
	}
});