// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
window.jQuery = $;
window.$ = $;
import Swal from 'sweetalert2';
window.Swal = Swal;

import LocalTime from "local-time"
import "./local_time_config";
LocalTime.start()

import Circles from 'circles.js';
window.Circles = Circles;

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

flatpickr.localize(Spanish);

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../stylesheets/application.scss"
import 'flatpickr/dist/flatpickr.min.css';
import "@fortawesome/fontawesome-free/css/all"

import "components"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// cocoon fo nested attibutes
require("@nathanvda/cocoon")

// select2 fo dynamic selects
import 'select2/dist/css/select2.css'
import 'select2'

window.PhotoSwipe = require("photoswipe/photoswipe")
window.PhotoSwipeUI_Default = require("photoswipe/photoswipe-ui-default")
import "../stylesheets/photoswipe/photoswipe.scss"
import "../stylesheets/photoswipe/default-skin.scss"

require("./init-basik")
